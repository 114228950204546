import { render, staticRenderFns } from "./TicketSelector.vue?vue&type=template&id=0adf227a&"
import script from "./TicketSelector.vue?vue&type=script&lang=js&"
export * from "./TicketSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsTicketSelector: require('/home/ticketshoplv/public_html/jarmolenka.tour.tickets/components/atoms/TicketSelector/TicketSelector.vue').default})
